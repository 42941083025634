<template>
  <Cascader
    :show-search="true"
    v-model:value="formState.terminalCategory"
    :options="options"
    placeholder="请选择..."
    change-on-select
    @blur="blurRegionValue"
    @change="change"
    :disabled="!isEdit"
    :allowClear="allowClear"
    @focus="focus"
  />
</template>
<script >
import { Cascader } from "ant-design-vue";
import { kindcategoryKindChannel } from "@/api/terminalList.js";
export default {
  components: {
    Cascader,
  },
  data() {
    return {
      options: [],
      cityCurrent: 0,
      kindCategryCode: "", //查询渠道的时候需要同时传通路大类和通路的code  此状态用于缓存通路大类code
    };
  },
  props: {
    formState: {
      type: Object,
      defaul: {
        terminalCategory: [],
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(value, selectedOptions) {
      this.$emit("myEvent", value);
    },
    blurRegionValue() {
      if (this.cityCurrent !== 1) {
        this.cityCurrent = 0;
      }
    },
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    focus() {
      function recursion(array) {
        array.forEach((element) => {
          element.value = element.name;
          element.label = element.name;
          if (element.children) {
            recursion(element.children);
          }
        });
      }
      kindcategoryKindChannel().then((res) => {
        recursion(res.data.data);
        this.options = res.data.data;
      });
    },
  },
  mounted() {
    this.focus();
  },
};
</script>
