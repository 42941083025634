
import { defineComponent } from "vue";
import { Cascader } from "ant-design-vue";
import { kindcategryList, kindList, channeList } from "@/api/terminalList.js";
export default defineComponent({
  components: {
    Cascader,
  },
  data() {
    return {
      options: [],
      cityCurrent: 0,
      kindCategryCode: "", //查询渠道的时候需要同时传通路大类和通路的code  此状态用于缓存通路大类code
    };
  },
  props: {
    formState: {
      type: Object,
      defaul: {
        terminalCategory: [],
      },
    },
  },
  methods: {
    blurRegionValue() {
      if (this.cityCurrent !== 1) {
        this.cityCurrent = 0;
      }
    },
    //懒加载
    loadData(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      let keepOption;
      if (targetOption.level === 1) {
        this.kindCategryCode = targetOption.code;
        kindList(targetOption.code).then((res: any) => {
          targetOption.loading = false;
          let newOptions = res.data.data.map((item) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
              isLeaf: false,
              level: item.level,
            };
          });

          targetOption.children = newOptions;
          this.options = [...this.options];
          keepOption = [...newOptions];
          this.cityCurrent = this.cityCurrent + 1;
        });
      }
      if (targetOption.level === 2) {
        let flag = this.options.filter((i: any) => {
          return i.level == targetOption.level;
        }).length;
        if (flag) {
          this.cityCurrent = 0;
          this.loadData(selectedOptions);
          return;
        }
        channeList(this.kindCategryCode, targetOption.code).then((res: any) => {
          targetOption.loading = false;
          let newOptions = res.data.data.map((item) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
              level: item.level,
              isLeaf: true,
            };
          });
          targetOption.children = newOptions;
          let areaFlag = keepOption.filter((i: any) => {
            return i.level == targetOption.level;
          }).length;
          if (areaFlag) {
            this.cityCurrent = 1;
            this.loadData(selectedOptions);
            return;
          } else {
            this.options = [...this.options];
            this.cityCurrent = 1;
          }
        });
      }
    },
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    getFirstList() {
      kindcategryList().then((res: any) => {
        let newOptions = res.data.data.map((item) => {
          return {
            ...item,
            value: item.name,
            label: item.name,
            isLeaf: false,
          };
        });
        if ((this as any).formState.terminalCategory.length == 0) {
          this.options = newOptions;
        }
        newOptions.forEach((elem) => {
          if (elem.name == (this as any).formState.terminalCategory[0]) {
            kindList(elem.code).then((res1: any) => {
              elem.children = res1.data.data.map((item) => {
                return {
                  ...item,
                  value: item.name,
                  label: item.name,
                  isLeaf: false,
                };
              });
              elem.children.forEach((element) => {
                if (
                  element.name == (this as any).formState.terminalCategory[1]
                ) {
                  channeList(elem.code, element.code).then((res2: any) => {
                    element.children = res2.data.data.map((item) => {
                      return {
                        ...item,
                        value: item.name,
                        label: item.name,
                        isLeaf: true,
                      };
                    });
                    //最终赋值
                    this.options = newOptions;
                  });
                }
              });
            });
          }
        });
      });
    },
  },
  mounted() {
    this.getFirstList();
  },
});
