
import { defineComponent } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Cascader,
  Table,
  Pagination,
  Popover,
  message,
  Switch,
  Checkbox,
  Select,
  Form,
} from "ant-design-vue";
import {
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  StopOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  TagsFilled,
  SyncOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import {
  provinceCityAread,
  masterList,
  terminalDisable,
  subchannelList,
  getTown,
} from "../../../api/terminalList.js";
import { terminalListColumns } from "@/api/fixedData.js";
import TerminalCategory from "@/components/currency/TerminalCategory.vue";
import AllCategory from "@/components/modification/AllCategory.vue";
let { Group: CheckboxGroup } = Checkbox;
const { Option } = Select;
export default defineComponent({
  components: {
    Col,
    Row,
    Input,
    Button,
    Cascader,
    SettingOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    CloudUploadOutlined, //上传图标
    CloudDownloadOutlined, //下载图标
    FileAddFilled, //新建图标
    StopOutlined, //电源图标
    TagsFilled,
    SyncOutlined,
    SearchOutlined,
    Table,
    Pagination,
    Popover,
    Switch,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup,
    Select,
    Option,
    TerminalCategoryCascader: TerminalCategory,
    Form,
    AllCategory,
  },
  data() {
    return {
      //各个筛选框的值待修改
      fmesCodeOrName: "", //租户名字或code
      matchResult: null, //AI匹配结果
      optionsMatchResult: [
        // { value: "", label: "全部" },
        { value: "AI匹配", label: "AI匹配" },
        { value: "无匹配结果", label: "无匹配结果" },
      ], //AI匹配结果的下拉框
      taskStatus: null, //实地认证状态
      optionsTaskStatus: [
        // { value: "", label: "全部" },
        { value: "人工认证", label: "人工认证" },
        { value: "实地认证", label: "实地认证" },
        { value: "AI认证", label: "AI认证" },
      ], //同上
      channel: "", //渠道
      optionsChannel: [], //同上
      subChannel: null, //子渠道
      optionsSubChannel: [], //同上
      kindCategry: "", //通路大类
      optionsKindCategry: [{ value: 0, label: "子1" }], //同上
      kind: "", //通路
      optionsKind: [{ value: 0, label: "子1" }], //同上
      scaleType: null, //规模类型
      optionsScaleType: [
        // { value: "", label: "全部" },
        { value: "独立", label: "独立" },
        { value: "连锁", label: "连锁" },
      ], //同上
      townvalue: "",
      optionsTown: [],
      regionValue: [], //省市区值
      options: [], //后端接收省市区
      formState: {
        terminalCategory: [], //修改为 渠道 通路 通路大类联级此为传送过去的联级数组
      },
      cityCurrent: 0,
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      //复选框的选择结果
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNum: 0,
      //总数据量
      total: 0,
      searchFlag: false,
      // //拓展字段
      // customData: [],
      // keepCustomData: [],
      //table的布局
      scroll: { x: "100%" },
      //批量操作按钮加载
      batchLoading: false,
      loading: true,
      //table的样式
      batchStyle: "middle",
      areaCode: "", //存储区变量给街道使用
      townOptions: [],
      town: "",
    };
  },
  methods: {
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      //
      this.selectedRowKeys = selectedRowKeys;
    },
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNum = 0;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getTerminal();
    },
    changeCascader(value, selectedOptions) {
      this.areaCode = selectedOptions[selectedOptions.length - 1].code; //存储区变量给街道使用
    },
    //获取街道列表
    focusTown() {
      getTown(this.areaCode).then((res: any) => {
        res.data.data.map((ele) => {
          ele.value = ele.name;
          ele.label = ele.name;
        });
        this.townOptions = res.data.data;
      });
    },
    //跳转到终端详情页面
    terminalDetails(id) {
      if (id === undefined) {
        id = "new";
      }
      if (this.$route.path.includes("terminalApp")) {
        this.$router.push(`/terminalApp/details/${id}`);
      } else {
        (this as any).$router.push(`/terminalDetails/${id}`);
      }
    },
    //操作终端 单个
    terminalHandle(id, status) {
      status = status == "禁用" ? "开启" : "禁用";
      terminalDisable({
        fmesCode: id,
        terminalStatus: status,
      }).then((res) => {
        if (res.data.success) {
        }
        this.getTerminal();
      });
    },
    // //禁用终端 批量
    // terminalDisable(id) {
    //
    //   if (id.length == 0) {
    //     message.warning("请先勾选想要禁用终端");
    //     return;
    //   }
    //   let params = {
    //     ids: [...id],
    //     status: 0,
    //   };
    //   terminalDisable(params).then((res) => {
    //     if (res.data.success) {
    //       message.success("批量禁用成功");
    //     }
    //     this.getTerminal();
    //   });
    // },
    //获取终端列表
    getTerminal() {
      this.loading = true;
      masterList({
        fmesCodeOrName: this.fmesCodeOrName,
        unifyType: this.taskStatus,
        province: this.regionValue[0],
        city: this.regionValue[1],
        area: this.regionValue[2],
        town: this.town,
        kindCategory: this.formState.terminalCategory[0],
        kind: this.formState.terminalCategory[1],
        channel: this.formState.terminalCategory[2],
        subChannel: this.subChannel,
        scaleType: this.scaleType,
        pageSize: this.pageSize,
        pageNumber: this.pageNum,
      })
        .then((res) => {
          let result = res.data.data;
          //--------------------------------------------------------------------------------待处理看看后端怎么传
          result.forEach((element) => {
            if (!element.taskStatys) {
              element.taskStatys = "";
            }
          });
          this.total = res.data.total;
          this.data = result;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //重置
    reset() {
      this.fmesCodeOrName = "";
      this.taskStatus = null;
      // this.kindCategry = "";
      // this.kind = "";
      // this.channel = "";
      this.subChannel = null;
      this.regionValue = [];
      this.scaleType = null;
      this.formState.terminalCategory = [];
      this.pageNum = 0;
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNum = 0;
      this.getTerminal();
      this.pageCurrent = 1;
    },
    //计算当前是否禁用状态
    computeStatus(status) {
      if (status == "禁用") {
        return false;
      } else {
        return true;
      }
    },
    //搜索子渠道
    searchSubChannel(val) {
      subchannelList(val).then((res: any) => {
        let array = res.data.data.map((item) => {
          return { value: item.subChannelName, label: item.subChannelName };
        });
        (this as any).optionsSubChannel = [
          // { value: "", label: "全部" },
          ...array,
        ];
      });
    },
    //获取省市区的联级
    getOptions() {
      function recursion(array) {
        array.forEach((element) => {
          element.value = element.name;
          element.label = element.name;
          if (element.children) {
            recursion(element.children);
          }
        });
      }
      provinceCityAread().then((res: any) => {
        recursion(res.data.data);
        this.options = res.data.data;
      });
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      if (this.searchFlag === false) {
        this.fmesCodeOrName = "";
        this.taskStatus = null;
        this.subChannel = null;
        this.regionValue = [];
        this.scaleType = null;
        this.formState.terminalCategory = [];
        // this.kindCategry = "";
        // this.channel = "";
        // this.kind = "";
      }
      this.selectedRowKeys = [];
      this.pageNum = this.pageCurrent - 1;
      this.getTerminal();
    },
    regionValue(be, af) {
      if (be.join("/") !== af.join("/")) {
        this.town = "";
      }
    },
  },
  mounted() {
    this.getOptions();
    //获取终端列表信息
    this.getTerminal();
    (this as any).columns = terminalListColumns;
    //默认获取一次子渠道列表避免影响观感
    this.searchSubChannel("");
  },
});
